<template>
  <div class="page-content" v-if="data">
    <div style="background-color: #FFFFFF;padding: 20px;">
      <img src="../assets/images/ss/company-13.png" style="width: 100%">
    </div>
    <div style="background-color: #FFFFFF;padding: 20px;margin-top: 40px;">
      <el-table :data="data" stripe class="large-font-table" tooltip-effect="light">
        <el-table-column prop="name" label="名称" show-overflow-tooltip>
          <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/company/details',query:{id:row.id}}).href">{{row.name}}</a>
        </el-table-column>
        <el-table-column prop="legalPerson" label="法人" width="90" show-overflow-tooltip></el-table-column>
        <el-table-column prop="registerCapital" label="注册资本" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="establishDate" label="成立日期" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="地址" width="300" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyList",
  data(){
    return {
      data:[
        {
          id:165726,
          name:'北京北辰实业股份有限公司',
          legalPerson:'贺江川',
          registerCapital:'336702万',
          establishDate:'1998-10-09',
          address:'北京市朝阳区北辰东路8号'
        },
        {
          id:165572,
          name:'东浩兰生会展集团股份有限公司',
          legalPerson:'曹炜',
          registerCapital:'42064',
          establishDate:'1982-08-07',
          address:'上海市浦东新区陆家嘴东路161号2602室'
        },
        {
          id:154415,
          name:'浙江米奥兰特商务会展股份有限公司',
          legalPerson:'潘建军',
          registerCapital:'7512万',
          establishDate:'2010-06-30',
          address:'浙江省杭州市经济技术开发区白杨街道6号大街452号'
        },
        {
          id:721461,
          name:'广东电声市场营销股份有限公司',
          legalPerson:'梁定郊',
          registerCapital:'36000万',
          establishDate:'2010-02-10',
          address:'广东省广州市天河区黄埔大道西平云路163号广电平云广场AB塔自编之B塔7层701室'
        },
        {
          id:164267,
          name:'广东省广告集团股份有限公司',
          legalPerson:'陈钿隆',
          registerCapital:'174333万',
          establishDate:'1981-05-11',
          address:'广东省广州市海珠区新港东路996号保利世界贸易中心G座'
        },
        {
          id:165653,
          name:'浙文互联集团股份有限公司',
          legalPerson:'刘锋杰',
          registerCapital:'132557万',
          establishDate:'1993-12-17',
          address:'浙江省杭州市临安区锦南街道九州街88号'
        },
        {
          id:159859,
          name:'上海艾德韦宣股份有限公司',
          legalPerson:'伍宝星',
          registerCapital:'5000万',
          establishDate:'2013-11-22',
          address:'上海市长宁区广顺路33号8幢303室'
        },
        {
          id:158272,
          name:'北京蓝色方略整合营销顾问股份有限公司',
          legalPerson:'罗坚',
          registerCapital:'3071万',
          establishDate:'2010-09-16',
          address:'北京市朝阳区酒仙桥北路9号(厂区)10幢二层A7-01'
        },
        {
          id:159966,
          name:'中青博联整合营销顾问股份有限公司',
          legalPerson:'袁浩',
          registerCapital:'10370万',
          establishDate:'2007-05-16',
          address:'北京市东城区东直门南大街5号中青旅大厦16层(东二环)'
        },
        {
          id:722688,
          name:'北京海天网联营销策划股份有限公司',
          legalPerson:'周元晖',
          registerCapital:'1518万',
          establishDate:'1996-11-26',
          address:'北京市顺义区天竺镇天竺家园17号2层216室'
        }
      ]
    }
  },
  created() {
  },
  methods:{
  }
}
</script>

<style scoped>

</style>
