<template>
  <block title="最新违规行为" v-if="data">
    <el-table :data="data.content" stripe class="large-font-table" tooltip-effect="light">
      <el-table-column label="违法违规者" prop="company" width="250" show-overflow-tooltip></el-table-column>
      <el-table-column label="违规行为" prop="irregularities" show-overflow-tooltip></el-table-column>
      <el-table-column label="处罚种类" prop="type" width="140" show-overflow-tooltip></el-table-column>
      <el-table-column label="处罚机构" prop="org" width="150" show-overflow-tooltip></el-table-column>
      <el-table-column label="处罚时间" prop="time" width="120"></el-table-column>
    </el-table>
    <el-pagination layout="prev, pager, next" background
                   :total="data.totalElements" :current-page="data.number+1"
                   @current-change="loadData">
    </el-pagination>
  </block>
</template>

<script>
import Block from "./Block";
import api from "../api";

export default {
  name: "CompanyXzcfListBlock",
  components: {Block},
  data() {
    return {
      data: null
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.loadData(1)
    },
    async loadData(page){
      const params={
        page: page-1, size: 10
      }
      const data=(await api.listPenalty(params)).data
      data.content=data.content.map(item=>{
        return {
          company:item.targetCompanies.map(i=>i.name).join('、'),
          irregularities:item.irregularities,
          type:item.types?item.types.join('、'):'',
          org:item.organization,
          time:item.penaltyDate
        }
      })
      this.data=data
    }
  }
}
</script>

<style scoped>

</style>
