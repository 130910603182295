<template>
  <block title="会展行业上市公司财务分析">
    <el-select v-model="year" slot="actions" size="mini" style="width: 90px">
      <el-option v-for="item in years" :key="item" :label="item+'年'" :value="item"></el-option>
    </el-select>
    <el-row :gutter="20">
      <el-col :span="12">
        <company-finance-chart :year="year" :years="years" index="asset"></company-finance-chart>
      </el-col>
      <el-col :span="12">
        <company-finance-chart :year="year" :years="years" index="income"></company-finance-chart>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <company-finance-chart :year="year" :years="years" index="netProfit"></company-finance-chart>
      </el-col>
      <el-col :span="12">
        <company-finance-chart :year="year" :years="years" index="grossProfitMargin"></company-finance-chart>
      </el-col>
    </el-row>
  </block>
</template>

<script>
import Block from "./Block";
import CompanyFinanceChart from "./CompanyFinanceChart";
export default {
  name: "CompanyFinanceStatBlock",
  components:{Block,CompanyFinanceChart},
  data(){
    return {
      years:[2022,2021,2020],
      year:2022,
    }
  }
}
</script>

<style scoped>

</style>
