<template>
  <div>
    <div class="chart-title">按主营分类的{{indexTitle}}</div>
    <div style="text-align: right;margin-bottom: 10px;">
      <el-link v-if="view==='chart'" icon="el-icon-date" type="primary" @click="showTable">表格</el-link>
      <el-link v-else icon="el-icon-data-analysis" type="primary" @click="showChart">柱图</el-link>
    </div>
    <v-chart v-if="view==='chart'" class="chart" :option="option"/>
    <el-table v-else :data="tableData" max-height="300">
      <el-table-column label="上市公司" prop="stockName"></el-table-column>
      <el-table-column label="主营分类" prop="industry"></el-table-column>
      <el-table-column v-for="year in years" :key="year" :label="year+'年'">
        <el-table-column :label="indexTableLabel" :prop="''+year">
          <span slot-scope="{row}">{{formatValue(row,year)}}</span>
        </el-table-column>
        <el-table-column label="年报" width="50">
          <el-link slot-scope="{row}" size="mini" type="primary" :href="row['url'+year]" target="_blank">查看</el-link>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {GridComponent, TitleComponent, TooltipComponent,} from "echarts/components";
import VChart from "vue-echarts";
import '../echarts/map'
import {getCompanyFinanceDataByIndex, statCompanyFinanceDataByIndustry} from '../api/data'

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  GridComponent,
]);

export default {
  name: "CompanyFinanceChart",
  props: {
    year: Number,
    years: Array,
    index: String
  },
  components: {
    VChart,
  },
  data() {
    return {
      view: 'chart',
      chartData: null,
      tableData: null
    }
  },
  created() {
    this.init()
  },
  watch: {
    year(){
      this.init()
    },
    years(){
      this.init()
    }
  },
  computed: {
    indexTitle(){
      return {asset:'资产规模',income:'收入规模',netProfit:'净利润均值情况',grossProfitMargin:'毛利率均值情况'}[this.index]
    },
    yName(){
      return {asset:'单位（万元）',income:'单位（万元）',netProfit:'单位（万元）',grossProfitMargin:'单位（%）'}[this.index]
    },
    indexTableLabel(){
      return {asset:'资产总计',income:'营业收入',netProfit:'净利润',grossProfitMargin:'毛利率'}[this.index]
    },
    option(){
      const chartData = this.chartData||[]
      return {
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: 'category',
          data: chartData.map(i=>i.key),
          // axisLabel:{
          //   rotate:45
          // }
        },
        yAxis: {
          type: 'value',
          name: this.yName
        },
        series: [
          {
            data: chartData.map(i=>{
              if(this.index==='netProfit'){
                return (i.avg/10000).toFixed(4)
              }else if(this.index==='grossProfitMargin'){
                return i.avg.toFixed(4)
              }else{
                return (i.sum/10000).toFixed(4)
              }
            }),
            type: 'bar',
            label:{
              show:true,
              position:'top'
            },
            barMaxWidth:'40'
          }
        ]
      }
    },
  },
  methods: {
    init() {
      if(this.view==='chart'){
        this.showChart()
      }else{
        this.showTable()
      }
    },
    showChart() {
      this.chartData = statCompanyFinanceDataByIndustry(this.year, this.index)
      this.view='chart'
    },
    showTable(){
      this.tableData=getCompanyFinanceDataByIndex(this.years,this.index)
      this.view='table'
    },
    formatValue(row,year){
      const value=row[''+year]
      if(value===null||value===undefined){
        return 'N/A'
      }else if(this.index==='netProfit'){
        return (value/10000).toFixed(4)+'万元'
      }else if(this.index==='grossProfitMargin'){
        return value.toFixed(4)+"%"
      }else{
        return (value/10000).toFixed(4)+'万元'
      }
    }
  }
}
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
