<template>
  <div class="page-content">
    <el-row>
      <el-col :span="24">
        <block title="会展产业链地图">
          <img src="../assets/images/ss/company-1.png" style="width: 100%">
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="ICCA成员">
          <el-row>
            <el-col style="width: 15%">
            <el-image :src="require('../assets/images/icca_logo.png')" lazy fit="fill"></el-image>
            </el-col>
            <el-col style="width: 85%">
              <icca-member></icca-member>
            </el-col>
          </el-row>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="UFI认证成员">
          <el-row>
            <el-col style="width: 15%">
              <el-image :src="require('../assets/images/ufi_logo.png')" lazy fit="fill"></el-image>
            </el-col>
            <el-col style="width: 85%">
              <ufi-certified-member></ufi-certified-member>
            </el-col>
          </el-row>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <company-list-block></company-list-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <company-bgcz-list-block></company-bgcz-list-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <company-finance-stat-block></company-finance-stat-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <company-qa-block></company-qa-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <company-funding-list-block></company-funding-list-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <company-xzcf-list-block></company-xzcf-list-block>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Block from "./Block";
import CompanyListBlock from "./CompanyListBlock";
import CompanyQaBlock from "./CompanyQaBlock";
import CompanyFundingListBlock from "./CompanyFundingListBlock";
import CompanyXzcfListBlock from "./CompanyXzcfListBlock";
import CompanyFinanceStatBlock from "./CompanyFinanceStatBlock";
import CompanyBgczListBlock from "./CompanyBgczListBlock";
import IccaMember from "./IccaMember";
import UfiCertifiedMember from "./UfiCertifiedMember";

export default {
  name: "CompanyHome",
  components: {
    UfiCertifiedMember,
    IccaMember,
    CompanyBgczListBlock,
    Block,
    CompanyListBlock,
    CompanyQaBlock,
    CompanyFundingListBlock,
    CompanyXzcfListBlock,
    CompanyFinanceStatBlock
  },
  data(){
    return {
    }
  },
  created() {
  },
  methods:{
  }
}
</script>

<style scoped>

</style>
