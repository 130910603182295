<template>
  <block title="投资者互动（会展领域上市公司）" v-if="data">
    <el-select v-model="comCode" slot="actions" size="mini" filterable clearable placeholder="选择公司" @change="init">
      <el-option v-for="item in companies" :key="item.id" :label="item.name" :value="item.code"></el-option>
    </el-select>
    <div class="company-qa" v-for="item in data.content" :key="item.id">
      <div class="company-qa-right">
        <div class="company-qa-question">
          <img src="../assets/images/question.png">
          <div class="company-qa-question-text" :title="item.questionCnt">投资者问：{{item.questionCnt}}</div>
          <div class="company-qa-time">{{item.collectTime.substring(0,10)}}</div>
        </div>
        <div class="company-qa-answer">
          <img src="../assets/images/answer.png">
          <div class="company-qa-answer-text"><span style="color: #000000;">{{item.comName}}：</span>{{item.replyCnt}}</div>
        </div>
      </div>
    </div>
    <el-pagination layout="prev, pager, next" background
                   :total="data.totalElements" :current-page="data.number+1"
                   @current-change="loadData">
    </el-pagination>
  </block>
</template>

<script>
import Block from "./Block";
import api from '../api'

export default {
  name: "CompanyQaBlock",
  components:{Block},
  data(){
    return {
      data:null,
      companies:[
        {
          id: 165726,
          name: '北京北辰实业股份有限公司',
          info: '上市主板 北辰实业(601588)',
          logo: '20180318073946148-15060.jpg',
          code:'601588'
        },
        {
          id: 165572,
          name: '东浩兰生会展集团股份有限公司',
          info: '上市主板 兰生股份(600826)',
          logo: '20180318073941542-35792.jpg',
          code:'600826'
        },
        {
          id: 723177,
          name: '上海风语筑文化科技股份有限公司',
          info: '上市主板 风语筑(603466)',
          logo: '8473701f-3080-45e2-ad1a-a224cee4f64b.jpg',
          code:'603466'
        },
        {
          id: 165529,
          name: '汉商集团股份有限公司',
          info: '上市主板 汉商集团(600774)',
          logo: '112613c8-7308-4356-b5ae-e982ff1f3b8e.jpg',
          code:'600774'
        },
        {
          id: 165068,
          name: '中青旅控股股份有限公司',
          info: '上市主板 中青旅(600138)',
          logo: '20180318073736152-49500.jpg',
          code:'600138'
        },
        {
          id: 165653,
          name: '浙文互联集团股份有限公司',
          info: '上市主板 浙文互联(600986)',
          logo: '20180318073944171-73555.jpg',
          code:'600986'
        }
      ],
      comCode:[]
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      await this.loadData(1)
    },
    async loadData(page){
      const params={
        comCode:this.comCode,
        page:page-1,
        size:5
      }
      this.data=(await api.listQaData(params)).data
    }
  }
}
</script>

<style scoped>

</style>
