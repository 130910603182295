<template>
  <div class="page-content" v-if="company">
    <div class="changguan-details-header">
      <div class="changguan-details-header-left">
        <div class="changguan-details-header-logo"><img v-attachment-attr:src="company.logo"></div>
      </div>
      <div class="changguan-details-header-right">
        <div class="changguan-details-title">
          {{company.name}}
        </div>
        <div class="changguan-details-fields">
          <div class="info-field" style="width: 33%">
            <span class="info-field-label">法定代表人：</span>
            <span class="info-field-value">{{company.legalPerson}}</span>
          </div>
          <div class="info-field" style="width: 33%">
            <span class="info-field-label">注册资本：</span>
            <span class="info-field-value">{{company.registerCapital}}</span>
          </div>
          <div class="info-field" style="width: 33%">
            <span class="info-field-label">成立时间：</span>
            <span class="info-field-value">{{company.establishDate}}</span>
          </div>
          <div class="info-field" style="width: 100%">
            <span class="info-field-label">地址：</span>
            <span class="info-field-value">{{company.address}}</span>
          </div>
        </div>
      </div>
    </div>
    <el-row :gutter="30">
      <el-col :span="14">
        <block title="公司介绍">
          <div v-html="richTextToHtml(company.remarks)" style="height: 246px;overflow: auto;"></div>
        </block>
      </el-col>
      <el-col :span="10">
        <block title="产业地图分享">
          <img src="../assets/images/ss/company-4.png" style="width: 100%">
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="工商信息">
          <el-image :src="require('../assets/images/ss/company-5.png')" style="width: 100%;height: 401px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="公司年报">
          <el-image :src="require('../assets/images/ss/company-6.png')" style="width: 100%;height: 125px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="公司股东">
          <el-image :src="require('../assets/images/ss/company-7.png')" style="width: 100%;height: 200px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="证券资料">
          <el-image :src="require('../assets/images/ss/company-8.png')" style="width: 100%;height: 384px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="中标项目">
          <el-image :src="require('../assets/images/ss/company-9.png')" style="width: 100%;height: 193px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="财务分析">
          <el-image :src="require('../assets/images/ss/company-10.png')" style="width: 100%;height: 715px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="会展分析">
          <el-image :src="require('../assets/images/ss/company-11.png')" style="width: 100%;height: 286px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <block title="合作伙伴">
          <el-image :src="require('../assets/images/ss/company-12.png')" style="width: 100%;height: 799px;" lazy fit="fill"></el-image>
        </block>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import HtmlUtils from "../utils/HtmlUtils";
import Block from "./Block";
export default {
  name: "CompanyDetails",
  components:{
    Block,
  },
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      company:{
        id:165726,
        name:'北京北辰实业股份有限公司',
        legalPerson:'李伟东',
        registerCapital:'336702万',
        establishDate:'1998-10-09',
        address:'北京市朝阳区北辰东路8号',
        logo:'20180318073946148-15060.jpg',
        remarks:'北京北辰实业股份有限公司由北京北辰实业集团公司独家发起设立。1997年在香港发行H股(HK.0588)并于5月14日挂牌上市。2006年10月16日公司15亿股A股(SH.601588)在上海证券交易所挂牌上市。 发展物业业务承载着公司房地产业务的投资开发建设，包括中高档住宅、公寓、别墅、写字楼等多元化、多档次房地产项目。目前主要开发项目包括北辰绿色家园居住区、碧海方舟别墅、长岛澜桥别墅、长河玉墅别墅、香山清琴别墅以及国家会议中心、奥运媒体村和北辰大厦等项目。 公司承担了奥林匹克公园国家会议中心项目及奥运媒体村项目的开发，是国内唯一拥有两项奥运项目的房地产开发商。'
      },
    }
  },
  created() {
    this.init()
  },
  computed:{
  },
  methods: {
    async init(){
    },
    richTextToHtml(str){
      return HtmlUtils.richTextToHtml(str)
    }
  }
}
</script>

<style scoped>

</style>
