<template>
  <block title="会展领域上市公司">
    <div class="company-list-scroll">
      <div class="left-scroll-button"></div>
      <div class="company-list">
        <div class="company-item" v-for="item in data" :key="item.id">
          <div class="company-logo">
            <el-image :src="$api.getAttachmentUrl(item.logo)" lazy fit="fill"></el-image>
          </div>
          <div>
            <div class="company-name">
              <a class="link"
                 :href="$router.resolve({path:'/company/details',query:{id:item.id}}).href">{{ item.name }}</a>
            </div>
            <div class="company-info">{{ item.info }}</div>
          </div>
        </div>
      </div>
      <div class="right-scroll-button"></div>
    </div>
  </block>
</template>

<script>
import Block from "./Block";
import AttachmentAttrDirective from "./AttachmentAttrDirective";

export default {
  name: "CompanyListBlock",
  components: {Block},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      data: [
        {
          id: 165726,
          name: '北京北辰实业股份有限公司',
          info: '上市主板 北辰实业(601588)',
          logo: '20180318073946148-15060.jpg',
        },
        {
          id: 165572,
          name: '东浩兰生会展集团股份有限公司',
          info: '上市主板 兰生股份(600826)',
          logo: '20180318073941542-35792.jpg',
        },
        {
          id: 723177,
          name: '上海风语筑文化科技股份有限公司',
          info: '上市主板 风语筑(603466)',
          logo: '8473701f-3080-45e2-ad1a-a224cee4f64b.jpg',
        },
        {
          id: 165529,
          name: '汉商集团股份有限公司',
          info: '上市主板 汉商集团(600774)',
          logo: '112613c8-7308-4356-b5ae-e982ff1f3b8e.jpg',
        },
        {
          id: 165068,
          name: '中青旅控股股份有限公司',
          info: '上市主板 中青旅(600138)',
          logo: '20180318073736152-49500.jpg',
        },
        {
          id: 165653,
          name: '浙文互联集团股份有限公司',
          info: '上市主板 浙文互联(600986)',
          logo: '20180318073944171-73555.jpg',
        }
      ]
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
    }
  }
}
</script>

<style scoped>

</style>
