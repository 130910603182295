<template>
  <block title="会展行业并购">
    <div class="">
      <el-table :data="data.content" stripe class="large-font-table" tooltip-effect="light">
        <el-table-column type="index" label="序号" :index="index">
        </el-table-column>
        <el-table-column label="公司" prop="companyName" width="160" show-overflow-tooltip></el-table-column>
        <el-table-column label="标题" show-overflow-tooltip>
          <a class="link" slot-scope="{row}" :href="row.url" target="_blank">{{ row.title }}</a>
        </el-table-column>
        <el-table-column label="标的" prop="target" width="280" show-overflow-tooltip></el-table-column>
        <el-table-column label="交易金额（万元）" prop="money" width="140" show-overflow-tooltip></el-table-column>
        <el-table-column label="事件进展" prop="process" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column label="披露日期" prop="publishTime" width="120"></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </block>
</template>

<script>
import Block from "./Block";
import AttachmentAttrDirective from "./AttachmentAttrDirective";
/*import api from "../api";*/

export default {
  name: "CompanyBgczListBlock",
  components: {Block},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      sourceData: {
        content: [
          {
            id: 85220,
            companyName: '兰生股份(600826)',
            title: '重大资产置换并发行股份及支付现金购买资产暨关联交易报告书（草案）（四次修订稿）',
            url: 'http://static.cninfo.com.cn/finalpage/2020-10-09/1208521449.PDF',
            target: '会展集团100%股权、上海兰生轻工业品进出口有限公司51%股权',
            money: '136300',
            process: '结束',
            publishTime: '2023-07-20',
          },
          {
            id: 200929,
            companyName: '北辰实业(601588)',
            title: '北辰实业关于首都会展（集团）有限公司收购股权暨关联交易的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2022-06-18/1213742150.PDF',
            target: '北京国际展览中心有限公司100%股权',
            money: '11200',
            process: '预先披露',
            publishTime: '2022-06-18',
          },
          {
            id: 198878,
            companyName: '华凯创意(300592)',
            title: '关于公司购买固定资产取得不动产权证的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2022-04-13/1212910922.PDF',
            target: '位于深圳市福田区香蜜湖街道红荔西路8089号深业中城（B302-0040）二期6号楼A单元商务公寓3805房号',
            money: '2378.5',
            process: '预先披露',
            publishTime: '2022-04-13',
          },
          {
            id: 195966,
            companyName: '易尚展示(002751)',
            title: '关于控股股东协议转让部分公司股份的提示性公告',
            url: 'http://static.cninfo.com.cn/finalpage/2022-01-13/1212160357.PDF',
            target: '深圳市易尚展示股份有限公司4.9%股权',
            money: '11800.0',
            process: '预先披露',
            publishTime: '2022-01-13',
          },
          {
            id: 189295,
            companyName: '华凯创意(300592)',
            title: '关于公司对外投资暨关联交易的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2021-12-03/1211791830.PDF',
            target: '深圳市华易鑫达投资有限公司44%股权',
            money: '440.0',
            process: '预先披露',
            publishTime: '2021-12-03',
          },
          {
            id: 186866,
            companyName: '*ST米奥(300795)',
            title: '关于公司拟公开摘牌受让中纺广告展览有限公司60%股权的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2021-10-26/1211371723.PDF',
            target: '中纺广告展览有限公司60%股权',
            money: '2992.54',
            process: '预先披露',
            publishTime: '2021-10-26',
          },
          {
            id: 183673,
            companyName: '华凯创意(300592)',
            title: '关于公司出售固定资产暨公司实际控制人提供关联担保的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2021-09-28/1211172585.PDF',
            target: '坐落于长沙市桐梓坡西路229号金泓园A7栋606、607、608、609、610、611、612室',
            money: '1349.01',
            process: '预先披露',
            publishTime: '2021-09-28',
          },
          {
            id: 180732,
            companyName: '易尚展示(002751)',
            title: '关于公司对外投资的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2022-01-13/1212160357.PDF',
            target: '深圳市易快来科技股份有限公司15%股权',
            money: '4200.0',
            process: '预先披露',
            publishTime: '2021-08-24',
          },
          {
            id: 186866,
            companyName: '*ST米奥(300795)',
            title: '关于拟出售股票资产的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2021-10-26/1211371725.PDF',
            target: '深圳华富展览服务有限公司51.00%的股权',
            money: '5253.0',
            process: '预先披露',
            publishTime: '2021-08-11',
          },
          {
            id: 178858,
            companyName: '华凯创意(300592)',
            title: '关于公司购买固定资产的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2021-06-29/1210364142.PDF',
            target: '深业中城（B302-0040）二期6号楼A单元商务公寓3805房号',
            money: '2378.5',
            process: '预先披露',
            publishTime: '2021-06-29',
          },
          {
            id: 180479,
            companyName: '*ST米奥(300795)',
            title: '关于公司对外投资的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2021-08-11/1210719368.PDF',
            target: '深圳华富展览服务有限公司51%股权',
            money: '0.0',
            process: '预先披露',
            publishTime: '2021-06-01',
          },
          {
            id: 167623,
            companyName: '易尚展示(002751)',
            title: '关于拟出售全资子公司股权的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2021-02-24/1209296375.PDF',
            target: '位于惠州市仲恺高新区沥林镇英光村英光一路8号房产',
            money: '22000.0',
            process: '预先披露',
            publishTime: '2021-02-24',
          },
          {
            id: 91184,
            companyName: '易尚展示(002751)',
            title: '关于公司与全资子公司股权内部转让的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2020-04-28/1207641818.PDF',
            target: '昆山易尚100%的股权；易尚香港100%的股权',
            money: '0.0',
            process: '预先披露',
            publishTime: '2020-04-28',
          },
          {
            id: 90288,
            companyName: '华凯创意(300592)',
            title: '2-1 湖南华凯文化创意股份有限公司发行股份及支付现金购买资产并募集配套资金暨关联交易报告书（注册稿）（修订稿）',
            url: 'http://reportdocs.static.szse.cn//UpFiles/rasinfodisc/RAS_000179B20026E63FE1319435BD18E83F.pdf',
            target: '易佰网络90%股权',
            money: '15.12',
            process: '结束',
            publishTime: '2020-03-19',
          },
          {
            id: 84059,
            companyName: '华凯创意(300592)',
            title: '发行股份、可转换公司债券及支付现金购买资产并募集配套资金暨关联交易报告书（草案）（二次修订稿）',
            url: 'http://static.cninfo.com.cn/finalpage/2020-01-02/1207219444.PDF',
            target: '易佰网络90%股权',
            money: '15.12',
            process: '预先披露',
            publishTime: '2019-06-17',
          },
          {
            id: 70097,
            companyName: '风语筑(603466)',
            title: '上海风语筑展示股份有限公司关于收购良晓科技 30%股权的公告',
            url: 'http://static.sse.com.cn/disclosure/listedinfo/announcement/c/2018-05-10/603466_20180510_2.pdf',
            target: '上海良晓信息科技有限公司30%股权',
            money: '3000.0',
            process: '预先披露',
            publishTime: '2018-05-10',
          },
          {
            id: 89954,
            companyName: '兰生股份(600826)',
            title: '关于控股子公司上海兰生轻工业品进出口有限公司收购上海新浩艺手套帽业进出口有限公司73%股权暨关联交易的公告',
            url: 'http://static.cninfo.com.cn/finalpage/2017-03-11/1203149006.PDF',
            target: '新浩艺73%股权',
            money: '1100.0',
            process: '预先披露',
            publishTime: '2017-03-11',
          },
          {
            id: 75617,
            companyName: '兰生股份(600826)',
            title: '关于控股子公司上海兰生轻工业品进出口有限公司收购上海升光轻工业品进出口有限公司51%股权暨关联交易的公告',
            url: 'http://static.sse.com.cn/disclosure/listedinfo/announcement/c/2016-12-22/600826_20161222_1.pdf',
            target: '上海升光轻工业品进出口有限公司51%股权',
            money: '958.8',
            process: '预先披露',
            publishTime: '2016-12-22',
          }
        ],
        totalElements: 18,
        number: 1,
      },
      data: {
        content: [],
        totalElements: 18,
        number: 1,
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.loadData(1)
    },
    async loadData(page){
      this.data.content=[]
      this.data.content=this.data.content.concat(this.sourceData.content)
      if(this.sourceData.totalElements>page*10){
        this.data.content=this.data.content.slice((page-1)*10,page*10)
      }else{
        this.data.content=this.data.content.slice((page-1)*10,this.sourceData.totalElements)
      }
      this.data.totalElements=this.sourceData.totalElements
      this.data.number=page-1
    },
    index(index){
      return this.data.number*10+index+1
    }
  }
}
</script>

<style scoped>

</style>
