<template>
  <div class="block-body" style="position: relative;">
    <div class="company-list-scroll">
      <div class="left-scroll-button"></div>
      <div class="icca-list">
        <ul>
          <li v-for="item in data" :key="item.id">
            <div class="li_div">
              <div class="content">{{ item.name}}</div>
            </div>
          </li>
        </ul>
        <ul>
          <li v-for="item in data1" :key="item.id">
            <div class="li_div">
              <div class="content">{{ item.name}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="right-scroll-button"></div>
    </div>
  </div>
</template>

<script>
import AttachmentAttrDirective from "./AttachmentAttrDirective";

export default {
  name: "UfiCertifiedMember",
  components: {},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      data: [
        {
          id: 165726,
          name: '安徽国际会展中心',
        },
        {
          id: 165572,
          name: '安徽中茂展览有限公司有限公司',
        },
        {
          id: 723177,
          name: '北京博乾国际会展服务有限公司有限',
        },
        {
          id: 165529,
          name: '公司北京国际展览中心有限公司',
        },
        {
          id: 165068,
          name: '北京兰普展览有限公司',
        },
        {
          id: 165653,
          name: '北京路易隆德展览有限公司',
        },
        {
          id: 165654,
          name: '北京北极星股份有限公司',
        },
        {
          id: 165655,
          name: '国家会议中心',
        },
        {
          id: 165656,
          name: '北京振威展览有限公司',
        },
        {
          id: 165657,
          name: '北京筑亿泰科技有限公司',
        },
        {
          id: 165657,
          name: '中国国际中小企业博览会局',
        }
      ],
      data1: [
        {
          id: 165726,
          name: '长沙国际会展中心',
        },
        {
          id: 165572,
          name: '湖北塔苏斯希望展览公司',
        },
        {
          id: 723177,
          name: '成都分公司',
        },
        {
          id: 165529,
          name: '成都世纪城会展集团有限公司',
        },
        {
          id: 165068,
          name: '成都世纪康博展览有限公司',
        },
        {
          id: 165653,
          name: '成都海鸣展览有限公司',
        },
        {
          id: 165654,
          name: '中国国际贸易促进委员会',
        },
        {
          id: 165655,
          name: '吉林省委员会中国家用电器协会',
        },
        {
          id: 165656,
          name: '中国国际会展杂志社',
        },
        {
          id: 165657,
          name: '中国商务部中国投资促进事务局(CIPA)',
        },
        {
          id: 165657,
          name: '中国汽车会展浙江有限公司',
        }
      ]
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
    }
  }
}
</script>

<style scoped>
.icca-list{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 20px;
}
ul {
  list-style: none; /* 移除默认的列表标记 */
  padding: 0px; /* 移除默认的内边距 */
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
li {
  position: relative; /* 为伪元素定位做准备 */
  padding-left: 20px; /* 留出空间显示自定义标记 */
  padding-bottom: 14px;
}
li::before {
  content: "•"; /* 自定义标记，这里是一个实心圆点 */
  font-size: 20px;
  position: absolute; /* 绝对定位，相对于其父元素（li） */
  left: 0; /* 放置在列表项文本的左侧 */
  color: #BFD9F4; /* 设置标记颜色 */
}
.li_div{
  width: 430px;
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
  flex-wrap: wrap;
}
.content {
  /* 可以在这里添加具体内容的样式 */
}
.content1 {
  /* 可以在这里添加具体内容的样式 */
  color: #555555;
}
</style>
