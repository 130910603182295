<template>
  <block title="会展领域股权融资" v-if="data">
    <el-table :data="data.content" tooltip-effect="light" class="large-font-table">
      <el-table-column label="项目">
        <div slot-scope="{row}" class="company-funding-logo-name">
          <el-image :src="$api.getAttachmentUrl(row.company.logo)" lazy fit="fill"></el-image>
          <span>{{ row.company.shortName || row.company.name }}</span>
        </div>
      </el-table-column>
      <el-table-column label="业务" prop="company.brief" show-overflow-tooltip></el-table-column>
      <el-table-column label="行业领域" width="140px" show-overflow-tooltip>
        <span slot-scope="{row}">{{row.company.firstLevelTags?row.company.firstLevelTags.join(','):''}}</span>
      </el-table-column>
      <el-table-column label="地区" prop="company.location" show-overflow-tooltip width="60px"></el-table-column>
      <el-table-column label="投资轮次" prop="roundName" show-overflow-tooltip width="90px"></el-table-column>
      <el-table-column label="投资时间" prop="fundingDate" show-overflow-tooltip width="120px"></el-table-column>
      <el-table-column label="投资金额" show-overflow-tooltip width="110px">
        <span slot-scope="{row}">{{formatMoney(row.formatInvestment,row.precise)}}{{row.formatInvestment?row.currencyName:''}}</span>
      </el-table-column>
      <el-table-column label="投资方" show-overflow-tooltip>
        <span slot-scope="{row}">{{ row.investors ? row.investors.map(i => i.shortName || i.name).join('、') : row.investorsRaw }}</span>
      </el-table-column>
    </el-table>
    <el-pagination layout="prev, pager, next" background
                   :total="data.totalElements" :current-page="data.number+1"
                   @current-change="loadData">
    </el-pagination>
  </block>
</template>

<script>
import Block from "./Block";
import api from "../api";
import AttachmentAttrDirective from "./AttachmentAttrDirective";

export default {
  name: "CompanyFundingListBlock",
  components: {Block},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      data: null
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.loadData(1)
    },
    async loadData(page){
      const params={
        page:page-1,
        size:10,
      }
      this.data=(await api.listFunding(params)).data
    },
    formatMoney(irInvestment, precise) {
      if (!irInvestment) {
        return '未知';
      } else if (irInvestment >= 100000000) {
        if (precise) {
          return (irInvestment / 100000000.0) + '亿';
        } else {
          return '数亿';
        }

      } else {
        if (precise) {
          return (irInvestment / 10000.0) + '万';
        } else {
          if (irInvestment >= 10000000) return '数千万';
          else if (irInvestment >= 1000000) return '数百万';
          else if (irInvestment >= 100000) return '数十万';
          else if (irInvestment >= 10000) return '数万';
        }

      }
    }
  }
}
</script>

<style scoped>

</style>
